import {
  type DtoZoomDecryptAppContextRequest,
  type DtoZoomDecryptAppContextResponse,
  type DtoZoomGetAuthorizeURLRequest,
  type DtoZoomGetAuthorizeURLResponse,
  type DtoZoomGetDeeplinkRequest,
  type DtoZoomGetDeeplinkResponse,
  type DtoZoomGetUserResponse,
  type DtoZoomLoginRequest,
  type DtoZoomLoginResponse,
} from '@lp-lib/api-service-client/public';

import {
  APIServiceURL,
  publicFetchAPIService,
} from '../../src/services/public';

export async function getZoomAuthorizeURL(req: DtoZoomGetAuthorizeURLRequest) {
  return await publicFetchAPIService<DtoZoomGetAuthorizeURLResponse>(
    new APIServiceURL(`/zoom/authorize`),
    { method: 'POST', bodyObj: req }
  );
}

export async function getZoomDeeplink(req: DtoZoomGetDeeplinkRequest) {
  return await publicFetchAPIService<DtoZoomGetDeeplinkResponse>(
    new APIServiceURL(`/zoom/get-deeplink`),
    { method: 'POST', bodyObj: req }
  );
}

export async function getZoomUser(uid: string) {
  return await publicFetchAPIService<DtoZoomGetUserResponse>(
    new APIServiceURL(`/zoom/users/${uid}`)
  );
}

export async function loginByZoom(req: DtoZoomLoginRequest) {
  return await publicFetchAPIService<DtoZoomLoginResponse>(
    new APIServiceURL(`/zoom/login`),
    {
      method: 'POST',
      bodyObj: req,
    }
  );
}

export async function decrptZoomAppContext(
  req: DtoZoomDecryptAppContextRequest
) {
  const resp = await publicFetchAPIService<DtoZoomDecryptAppContextResponse>(
    new APIServiceURL(`/zoom/decrypt-app-context`),
    {
      method: 'POST',
      bodyObj: req,
    }
  );
  return resp.json.context;
}

export async function getCodeChallenge(req: DtoZoomDecryptAppContextRequest) {
  const resp = await publicFetchAPIService<DtoZoomDecryptAppContextResponse>(
    new APIServiceURL(`/zoom/decrypt-app-context`),
    {
      method: 'POST',
      bodyObj: req,
    }
  );
  return resp.json.context;
}
