import { type HeadersFunction, type LoaderFunctionArgs } from '@remix-run/node';
import { Link, useLoaderData, useNavigate } from '@remix-run/react';
import {
  Fragment,
  type ReactNode,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useEffectOnce } from 'react-use';
import { useHydrated } from 'remix-utils/use-hydrated';
import { v4 as uuidv4 } from 'uuid';

import {
  type DtoZoomLoginResponse,
  EnumsZoomLoginMode,
  type ZoomDecryptedAppContext,
} from '@lp-lib/api-service-client/public';

import { LunaParkLogo } from '../../src/components/icons/LogoIcon';
import { Loading } from '../../src/components/Loading';
import config from '../../src/config';
import { useInstance } from '../../src/hooks/useInstance';
import { getLogger, safeWindowReload } from '../../src/logger/logger';
import { isErrorCausedByResponse } from '../../src/services/public';
import { useSnapshot } from '../../src/utils/valtio';
import { PageLoadingBar } from '../components/PageLoadingBar';
import { ZoomClient } from '../components/Zoom/client';
import { getZoomAppContext, setZoomEnv } from '../components/Zoom/utils';
import { decrptZoomAppContext, loginByZoom } from '../fetches/fetchZoom';
import { createSecureHeaders } from '../secure.headers';

export const log = getLogger().scoped('zoom');

export const headers: HeadersFunction = () => {
  return createSecureHeaders();
};

type Auth = DtoZoomLoginResponse;

type Data = {
  context: ZoomDecryptedAppContext;
  auth?: Auth;
};

export async function loader({ request }: LoaderFunctionArgs) {
  log.info('loader', {
    url: request.url,
    headers: Object.fromEntries(request.headers.entries()),
  });
  const zoomAppContext = getZoomAppContext(request);
  if (!zoomAppContext) return null;

  let decrypted: ZoomDecryptedAppContext | null = null;
  try {
    decrypted = await decrptZoomAppContext({ context: zoomAppContext });
    if (!decrypted.uid) {
      return { context: decrypted };
    }
  } catch (e) {
    log.error('decrypted error', e);
    throw e;
  }

  log.info('decrypted zoom app context', { decrypted });

  if (!decrypted) return null;

  try {
    log.info('loginByZoom start', { decrypted });
    const resp = await loginByZoom({
      zoomUid: decrypted.uid,
      mode: EnumsZoomLoginMode.ZoomLoginModeAuto,
    });
    log.info('loginByZoom done', { resp });
    return { context: decrypted, auth: resp.json };
  } catch (e) {
    if (isErrorCausedByResponse(e) && e.cause.status === 401) {
      return { context: decrypted };
    }
    log.error('loginByZoom error', e);
    throw e;
  }
}

function Authenticated(props: Auth) {
  const { token, zoomUser } = props;
  const navigate = useNavigate();

  useLayoutEffect(() => {
    log.info('zoom index - Authenticated', { token, zoomUser });
    localStorage.setItem('token', token);
    setZoomEnv(zoomUser);
    navigate('/zoom/home', { replace: true });
  }, [navigate, token, zoomUser]);

  return null;
}

function Unauthenticated(props: {
  zoomUid: string;
  setAuth: (auth: Auth) => void;
  client: ZoomClient;
  inited: number;
}) {
  const { zoomUid, setAuth, client, inited } = props;

  useEffectOnce(() => log.info('zoom index - Unauthenticated'));

  useEffect(() => {
    if (!inited) return;
    client.log.info('Zoom client inited', { inited });
    client.sdk.onAuthorized(async (event) => {
      client.log.info('Zoom authorized', event);
      const resp = await loginByZoom({
        zoomUid: zoomUid ? zoomUid : undefined,
        auth: {
          code: event.code,
          redirectURL: event.redirectUri,
          state: event.state,
        },
        mode: EnumsZoomLoginMode.ZoomLoginModeAuto,
      });
      setAuth(resp.json);
    });
    if (zoomUid) {
      const codeChallenge = uuidv4();
      client.authorize(codeChallenge);
    } else {
      client.promptAuthorize((error) => {
        // The _promptAuthorize_ is used for in-client authorization. However,
        // the behavior is different for users who have installed the Zoom app
        // after authentication.
        // For a totally freshed user, Zoom will prompt the user to install
        // the Zoom app. After that, Zoom auto reloads the page so we get the
        // correct Zoom user context.
        // For a user who has installed the Zoom app, calling _promptAuthorize_
        // will return an error with code 80010. I'm not sure the best way to
        // handle this error. A simple reload seems to work. Also keeping a flag
        // in sessionStorage to prevent infinite reloads.
        // code:80010, reason:disabled_for_installed_apps
        const errString = String(error);
        if (errString.includes('code:80010')) {
          const reloaded = window.sessionStorage.getItem(
            'zoomPromptAuthorizeReloaded'
          );
          client.log.info('Zoom SDK promptAuthorize try reload', { reloaded });
          if (reloaded) return;
          window.sessionStorage.setItem('zoomPromptAuthorizeReloaded', 'true');
          safeWindowReload();
        }
      });
    }
  }, [client, inited, setAuth, zoomUid]);

  return (
    <Layout>
      <div className='flex flex-col items-center justify-center'>
        {zoomUid ? (
          <Loading text='Authorizing' />
        ) : (
          <h1 className='text-2xl font-medium'>Please authorize first.</h1>
        )}
      </div>
    </Layout>
  );
}

function Layout(props: { children?: ReactNode }) {
  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-none h-15 bg-black border-secondary border-b flex items-center pl-10'>
        <div className='h-full flex-none flex items-center gap-7.5'>
          <div className='flex-none'>
            <Link to={config.app.homeUrlOrPathname}>
              <LunaParkLogo className='w-27 h-7.5' />
            </Link>
          </div>
        </div>
      </div>
      <div
        className={`relative w-full h-full flex flex-col overflow-auto 
          scrollbar bg-library-2023-07 items-center justify-center`}
      >
        {props.children}
      </div>
    </div>
  );
}

function InZoom(props: Data) {
  const { context } = props;
  const [auth, setAuth] = useState(props.auth);
  const client = useInstance(() => new ZoomClient());
  const state = useSnapshot(client.state);
  const inited = state.inited;
  const isHydrated = useHydrated();

  useEffect(() => {
    log.info('zoom index - InZoom - Hydration', { isHydrated });
  }, [isHydrated]);

  useEffect(() => {
    client.init();
  }, [client, context.uid]);

  if (!isHydrated) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  if (!!auth) return <Authenticated {...auth} />;

  return (
    <Unauthenticated
      zoomUid={context.uid}
      setAuth={setAuth}
      client={client}
      inited={inited}
    />
  );
}

function InstallZoomApp() {
  useEffectOnce(() => log.info('zoom index install entered'));

  return (
    <Layout>
      <div className='flex flex-col items-center justify-center'>
        <h1 className='text-2xl font-medium'>Make your Zoom Meetings Fun!</h1>
        <h2 className='text-base font-bold mt-4'>
          Connect your team by adding Ice Breakers, Parlor games and Trivia to
          your Zoom meetings
        </h2>

        <Link
          to='/zoom/install'
          className='btn-primary w-75 h-10 flex items-center justify-center mt-10'
        >
          Get the app
        </Link>
      </div>
    </Layout>
  );
}

function InlineTrackGeneral(props: { info: string }) {
  return (
    <script
      suppressHydrationWarning
      dangerouslySetInnerHTML={{
        __html: `
          navigator.sendBeacon("/zoom/log", "${props.info}");
        `,
      }}
    ></script>
  );
}

function InlineTrackCSPError() {
  return (
    <script
      suppressHydrationWarning
      dangerouslySetInnerHTML={{
        __html: `
          document.addEventListener('securitypolicyviolation', (e) => {
            navigator.sendBeacon("/zoom/log", JSON.stringify({
              blockedURI: e.blockedURI,
              violatedDirective: e.violatedDirective,
              effectiveDirective: e.effectiveDirective
            }));
          });
        `,
      }}
    ></script>
  );
}

function InlineTrackUnknownError() {
  return (
    <script
      suppressHydrationWarning
      dangerouslySetInnerHTML={{
        __html: `
          window.addEventListener('error', (e) => {
            navigator.sendBeacon("/zoom/log", JSON.stringify({
              msg: e.message,
              src: e.filename,
              lineno: e.lineno,
              colno: e.colno
            }));
          });
        `,
      }}
    ></script>
  );
}

export default function Component() {
  const data = useLoaderData<typeof loader>();
  useEffectOnce(() => log.info('zoom index entered', { ...data }));
  return (
    <div className='w-screen h-screen flex flex-col text-white'>
      <PageLoadingBar color='#f11946' height={3} />
      <noscript className='text-white absolute left-1/2 transform-gpu -translate-x-1/2 top-4'>
        You need to enable JavaScript to run this app.
      </noscript>
      {data ? (
        <Fragment>
          <InZoom {...data} />
          <InlineTrackGeneral
            info={`zoomUid - ${data.context.uid || 'unknown'}`}
          />
        </Fragment>
      ) : (
        <InstallZoomApp />
      )}
      <InlineTrackGeneral info='component loaded' />
      <InlineTrackCSPError />
      <InlineTrackUnknownError />
    </div>
  );
}
